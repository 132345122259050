import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Logo from './assets/maxsip-logo.png';

const MainConfirmationContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #ffffff;
  align-items: center;
  @media (min-width: 1441px) {
    width: 1440px;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
  @media (min-width: 1441px) {
    justify-content: space-evenly;
  }
`;
const LeftConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  @media (max-width: 1024px) {
    width: 50%;
    align-items: center;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
  @media (min-width: 1441px) {
    width: 40%;
  }
`;
const RightConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  @media (max-width: 1024px) {
    width: 50%;
    justify-content: flex-start;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
  @media (min-width: 1441px) {
    width: 40%;
  }
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ebf2fe;
  width: 533px;
  padding: 20px;
  margin-bottom: 8%;
  @media (max-width: 1230px) {
    width: 350px;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
  @media (min-width: 1441px) {
    width: 100%;
  }
`;

const ContactsCard = styled.div`
  display: none;
  @media (max-width: 768px) {
    flex-direction: column;
    border: 1px solid #ebf2fe;
    width: 90%;
    padding: 20px;
    margin-bottom: 8%;
    display: flex;
  }
  @media (min-width: 1441px) {
    width: 100%;
  }
`;
const ContactsCardRightSide = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ebf2fe;
  width: 533px;
  padding: 20px;
  margin-bottom: 8%;
  @media (max-width: 1230px) {
    width: 360px;
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (min-width: 1441px) {
    width: 100%;
  }
`;

const OptionalCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ebf2fe;
  width: 533px;
  padding: 20px;
  margin-bottom: 8%;
  @media (max-width: 1230px) {
    width: 350px;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
  @media (min-width: 1441px) {
    width: 100%;
  }
`;
const CardDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const CardContactsDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  margin-bottom: 20px;
  justify-content: space-between;
  @media (min-width: 1441px) {
    width: 100%;
  }
`;
const Title = styled.p`
  font-family: 'Fira Sans';
  color: #0092ff;
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  width: 200px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
const Value = styled.p`
  width: 200px;
  text-align: left;
  font-family: 'Fira Sans';
  color: #0a313f;
  font-size: 16px;
  font-weight: 300;
  margin: 0px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  @media (max-width: 768px) {
    font-size: 16px;
    margin-left: 0px;
  }

  @media (min-width: 1024px) {
    width: 90%;
  }
`;
const SubValue = styled.p`
  font-family: 'Fira Sans';
  color: #0a313f;
  font-size: 12px;
  font-weight: 300;
  margin: 0px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  @media (max-width: 768px) {
    font-size: 12px;
    margin-left: 0px;
  }
`;
const CardTitle = styled.p`
  color: #0a313f;
  font-size: 24px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
const ConfirmationText = styled.p`
  color: #0a313f;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;

  @media (max-width: 320px) {
    font-size: 16px;
  }
`;
const Confirmation = ({ allData, back, isUsingExistingPatient, existingPatientId }) => {
  const { consumerOrderId } = useParams();
  const navigate = useNavigate();
  const transformDeviceContacts = contacts => {
    return contacts.map(contact => ({
      Telephone: contact.telephone.includes('+') ? contact.telephone : `+${contact.telephone}`,
      FirstName: contact.firstName,
      LastName: contact.lastName,
      IsAdmin: contact.admin,
    }));
  };
  const transformedContacts = transformDeviceContacts(allData.deviceContacts);
  const submit = () => {
    let metaDataShipTo = allData?.shipTo;
    let fullName = '';
    let phoneNumber = '';
    if (allData.deviceUser.shipTo === 'DEVICE_ORDERER' && allData?.modifiedAddress?.deviceOrderer) {
      fullName = `${allData.orderer.deviceOrdererFirstName} ${allData.orderer.deviceOrdererLastName}`;
      phoneNumber = allData.orderer.deviceOrdererPhoneNumber;
      metaDataShipTo = {
        ...allData.shipTo,
        ...allData.modifiedAddress.deviceOrderer,
        name: fullName,
        phone: phoneNumber,
      };
    }
    if (allData.deviceUser.shipTo === 'DEVICE_USER' && allData?.modifiedAddress?.deviceUser) {
      fullName = `${allData.deviceUser.deviceUserFirstName} ${allData.deviceUser.deviceUserLastName}`;
      phoneNumber = allData.deviceUser.deviceUserPhoneNumber;
      metaDataShipTo = {
        ...allData.shipTo,
        ...allData.modifiedAddress.deviceUser,
        name: fullName,
        phone: phoneNumber,
      };
    }
    const payload = {
      deviceOrdererFirstName: allData?.orderer?.deviceOrdererFirstName,
      deviceOrdererLastName: allData?.orderer?.deviceOrdererLastName,
      deviceOrdererPhoneNumber: allData?.orderer?.deviceOrdererPhoneNumber,
      deviceOrdererAddress: allData?.orderer?.deviceOrdererAddress,
      deviceOrdererEmail: allData?.orderer?.deviceOrdererEmail,
      deviceContacts: transformedContacts,
      deviceUserFirstName: allData?.deviceUser?.deviceUserFirstName,
      deviceUserLastName: allData?.deviceUser?.deviceUserLastName,
      deviceUserPhoneNumber: allData?.deviceUser?.deviceUserPhoneNumber,
      deviceUserAddress: allData?.deviceUser?.deviceUserAddress,
      shipTo: allData?.deviceUser?.shipTo,
      metadata: {
        shipTo: metaDataShipTo || allData?.shipTo,
        deviceOrdererAge: allData?.optional?.deviceOrdererAge,
        deviceUserAge: allData?.optional?.deviceUserAge,
        deviceUserNumberOfResidentsInHousehold: allData?.optional?.deviceUserNumberOfResidentsInHousehold,
        deviceUserMaritalStatus: allData?.optional?.deviceUserMaritalStatus,
        deviceUserGender: allData?.optional?.deviceUserGender,
        additionalComments: allData?.optional?.additionalComments,
        wifiSsid: allData?.wifi?.wifiSsid,
        wifiPassword: allData?.wifi?.wifiPassword,
        deviceUserDeviceNames: allData?.deviceUser?.deviceUserDeviceNames,
      },
    };
    if (existingPatientId) {
      payload['deviceUserId'] = existingPatientId;
    }
    httpNodeApi
      .put(`consumer-orders/${consumerOrderId}`, payload)
      .then(() => {
        navigate('/success');
      })
      .catch(error => {
        console.log('Error: ', error);
      });
  };
  const formatPhoneNumber = phoneNumber => {
    if (!phoneNumber) return '';
    let countryCodeLength = 1;
    if (phoneNumber.length > 11) {
      if (!phoneNumber.startsWith('1')) {
        countryCodeLength = phoneNumber.length === 13 ? 3 : 2;
      }
    }

    const countryCode = phoneNumber.substring(0, countryCodeLength);
    const part1 = phoneNumber.substring(countryCodeLength, countryCodeLength + 3);
    const part2 = phoneNumber.substring(countryCodeLength + 3, countryCodeLength + 5);
    const part3 = phoneNumber.substring(countryCodeLength + 5);

    return `+${countryCode} (${part1}) ${part2}-${part3}`;
  };
  return (
    <MainConfirmationContainer>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <img alt="NucleusPoweredByMaxsip" src={Logo} style={{ marginTop: 30, width: 'fit-content' }} />
      </div>
      <p style={{ color: '#0A313F', fontSize: 12, fontWeight: '300', textAlign: 'center' }}>support@nucleuscare.com</p>
      <p style={{ color: '#0A313F', fontSize: 12, fontWeight: '300', textAlign: 'center' }}>(516) 951-1340</p>
      <div style={{ width: '80%' }}>
        <ConfirmationText>Almost finished; double-check your information and then click "Submit" to send it.</ConfirmationText>
      </div>
      <CardWrapper>
        <LeftConfirmationContainer>
          <Card>
            <CardTitle>Device Orderer</CardTitle>
            <CardDataContainer>
              <Title>Name:</Title>
              <Value>{allData?.orderer?.deviceOrdererFirstName + ' ' + allData?.orderer?.deviceOrdererLastName}</Value>
            </CardDataContainer>
            <CardDataContainer>
              <Title>Phone number:</Title>
              <Value>{'+' + allData?.orderer?.deviceOrdererPhoneNumber}</Value>
            </CardDataContainer>
            <CardDataContainer>
              <Title>Address:</Title>
              <Value>{allData?.orderer?.deviceOrdererAddress}</Value>
            </CardDataContainer>
            <CardDataContainer>
              <Title>Email:</Title>
              <Value>{allData?.orderer?.deviceOrdererEmail}</Value>
            </CardDataContainer>
          </Card>
          <Card>
            <CardTitle>Device User</CardTitle>
            <CardDataContainer>
              <Title>Phone number:</Title>
              <Value>{'+' + allData?.deviceUser?.deviceUserPhoneNumber}</Value>
            </CardDataContainer>
            <CardDataContainer>
              <Title>Name:</Title>
              <Value>{allData?.deviceUser?.deviceUserFirstName + ' ' + allData?.deviceUser?.deviceUserLastName}</Value>
            </CardDataContainer>
            <CardDataContainer>
              <Title>Address:</Title>
              <Value>{allData?.deviceUser?.deviceUserAddress}</Value>
            </CardDataContainer>
            {allData?.deviceUser?.deviceUserDeviceNames?.map((deviceName: string, index: number) => (
              <CardDataContainer key={deviceName}>
                <Title>{`Device Name ${index + 1}:`}</Title>
                <Value>{deviceName}</Value>
              </CardDataContainer>
            ))}
            <CardDataContainer>
              <Title>Ship to:</Title>
              <div>
                <Value>{allData?.deviceUser?.shipTo === 'DEVICE_USER' ? 'Device User' : 'Device Orderer'}</Value>
                <SubValue>{allData?.deviceUser?.shipTo === 'DEVICE_USER' ? allData?.deviceUser?.deviceUserAddress : allData?.orderer?.deviceOrdererAddress}</SubValue>
              </div>
            </CardDataContainer>
          </Card>

          <ContactsCard>
            <CardTitle>Device Contacts</CardTitle>
            {!isUsingExistingPatient &&
              allData?.deviceContacts.map((contact, index) => (
                <React.Fragment key={contact?.telephone}>
                  <label style={{ color: '#0A313F', fontSize: 16, fontWeight: '600' }}>{index + 1 + '.'}</label>
                  <CardContactsDataContainer>
                    <Title>Name:</Title>
                    <Value>{contact?.firstName + ' ' + contact?.lastName}</Value>
                  </CardContactsDataContainer>
                  <CardContactsDataContainer>
                    <Title>Phone number:</Title>
                    <Value>{formatPhoneNumber(contact?.telephone)}</Value>
                  </CardContactsDataContainer>
                  <CardContactsDataContainer>
                    <Title>Admin:</Title>
                    <Value>{contact?.admin ? 'Yes' : 'No'}</Value>
                  </CardContactsDataContainer>
                  <div style={{ width: '90%', border: '1px solid #B4C6D3', marginBottom: '10%' }} />
                </React.Fragment>
              ))}
            {isUsingExistingPatient && (
              <CardContactsDataContainer>
                <Value>The Device User entered already exists within our system. Contacts can be added on the Nucleus Device and/or the InTouch app.</Value>
              </CardContactsDataContainer>
            )}
          </ContactsCard>

          <OptionalCard>
            <CardTitle>Optional Questions</CardTitle>
            <CardDataContainer>
              <Title>Age of Purchaser:</Title>
              <Value>{allData?.optional?.deviceOrdererAge}</Value>
            </CardDataContainer>
            <CardDataContainer>
              <Title>Age of Nucleus Device User:</Title>
              <Value>{allData?.optional?.deviceUserAge}</Value>
            </CardDataContainer>
            <CardDataContainer>
              <Title>Number of Residents:</Title>
              <Value>{allData?.optional?.deviceUserNumberOfResidentsInHousehold}</Value>
            </CardDataContainer>
            <CardDataContainer>
              <Title>Marital Status:</Title>
              <Value>{allData?.optional?.deviceUserMaritalStatus}</Value>
            </CardDataContainer>
            <CardDataContainer>
              <Title>Gender:</Title>
              <Value>{allData?.optional?.deviceUserGender}</Value>
            </CardDataContainer>
            <CardDataContainer>
              <Title>Additional Feedback:</Title>
              <Value>{allData?.optional?.additionalComments}</Value>
            </CardDataContainer>
          </OptionalCard>
        </LeftConfirmationContainer>
        <RightConfirmationContainer>
          <ContactsCardRightSide>
            <CardTitle>Device Contacts</CardTitle>
            {!isUsingExistingPatient &&
              allData?.deviceContacts.map((contact, index) => (
                <React.Fragment key={contact.telephone}>
                  <label style={{ color: '#0A313F', fontSize: 16, fontWeight: '600' }}>{index + 1 + '.'}</label>
                  <CardContactsDataContainer>
                    <Title>Name:</Title>
                    <Value>{contact?.firstName + ' ' + contact?.lastName}</Value>
                  </CardContactsDataContainer>
                  <CardContactsDataContainer>
                    <Title>Phone number:</Title>
                    <Value>{formatPhoneNumber(contact?.telephone)}</Value>
                  </CardContactsDataContainer>
                  <CardContactsDataContainer>
                    <Title>Admin:</Title>
                    <Value>{contact?.admin ? 'Yes' : 'No'}</Value>
                  </CardContactsDataContainer>
                  <div style={{ width: '90%', border: '1px solid #B4C6D3', marginBottom: '10%' }} />
                </React.Fragment>
              ))}
            {isUsingExistingPatient && (
              <CardContactsDataContainer>
                <Value>The Device User entered already exists within our system. Contacts can be added on the Nucleus Device and/or the InTouch app.</Value>
              </CardContactsDataContainer>
            )}
          </ContactsCardRightSide>
        </RightConfirmationContainer>
      </CardWrapper>
      <div style={{ width: '80%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '3%' }}>
        <button onClick={back} style={{ background: 'transparent', border: '1px solid #0092FF', width: '40%', borderRadius: 100 }}>
          <p style={{ color: '#0092FF', fontSize: 16, fontWeight: '400' }}>Go Back</p>
        </button>
        <button onClick={submit} style={{ background: '#0092FF', border: '1px solid #0092FF', width: '40%', borderRadius: 100 }}>
          <p style={{ color: '#FFFFFF', fontSize: 16, fontWeight: '400' }}>Submit</p>
        </button>
      </div>
    </MainConfirmationContainer>
  );
};

export default Confirmation;
